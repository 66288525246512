// Declare turnstileToken globally so it can be accessed anywhere in this script
let turnstileToken = '';  // Variable to hold the Turnstile token

$(document).ready(function() {
  // Hide HoneyPot
  $('#companyemail').addClass('hide');
});

$(function() {

  // Initialize form validation on the registration form
  $("form[name='contact-form']").validate({
    // Specify validation rules
    rules: {
      fullname: "required",
      email: {
        required: true,
        email: true
      },
      message: "required"
    },
    // Specify validation error messages
    messages: {
      fullname: "",
      email: "",
      message: ""
    },

    highlight: function (element) {
      $(element).closest('.control').removeClass('has-success').addClass('has-error');
    },
    unhighlight: function (element) {
      $(element).closest('.control').removeClass('has-error').addClass('has-success');
    },

    // Make sure the form is submitted to the destination defined
    // in the "action" attribute of the form when valid
    submitHandler: function(form, event) {
      event.preventDefault();

      // Prevent form submission if Turnstile token is not set
      if (!turnstileToken) {
        alert("Please complete the Turnstile challenge.");
        return;
      }

      var s = form,
          e = $(s).siblings(".contact-form-result"),
          o;

      // Escape if the honeypot has been filled
      if (!!$("#companyemail").val() || !!$("#secondcontact").val()) return;

      var fullname = $("#fullname").val();
      var email = $("#email").val();
      var message = $("#message").val();
      var messageLanguage = $("#language").val();
      var data = {
         messageLanguage: messageLanguage,
         fullname: fullname,
         email: email,
         message: message
      };

      $.ajax({
          url: "https://igw0p0fiwa.execute-api.ap-south-1.amazonaws.com/production/sendMail",
          type: "post",
          dataType: "json",
          crossDomain: "true",
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify(data),

          beforeSend: function() {
              $('#submit').attr('disabled', true);
              if ($("#language").val() == "km") {
                  o = '<p class="form-message form-success">កំពុង​ផ្ញើ...</p>';
              } else {
                  o = '<p class="form-message form-success">Sending...</p>';
              }
              e.removeClass("hidden").html(o);
          },

          success: function (data) {
              if (data == "Email sent!") {
                  if ($("#language").val() == "km") {
                      var successMessage = "អរគុណ​សម្រាប់​ការ​ផ្ញើ​សារ...";
                  } else {
                      var successMessage = "Thank you for your message...";
                  }
              }
              o = '<p class="form-message form-success">' + successMessage + '</p>';
              e.removeClass("hidden").html(o);
              $(s)[0].reset();
              setTimeout(function() {
                  e.addClass("hidden").html("");
              }, 5000);
              $('#submit').attr('disabled', false);
          },
          error: function (jqXHR, textStatus, errorThrown) {
              var errorMessage = $("#language").val() == "km" 
                  ? 'សូម​មេត្តា​បំពេញ​ពេល​ក្រោយ...'
                  : 'Sorry, there was an error. Please try again later. ERROR: ' + jqXHR.status;
              o = '<p class="form-message form-error">' + errorMessage + '</p>';
              e.removeClass("hidden").html(o);
              setTimeout(function() {
                  e.addClass("hidden").html("");
              }, 5000);
              $('#submit').attr('disabled', false);
          }
      });
    }
  });

  // Function to enable the submit button when Turnstile is successful
  function onTurnstileSuccess(token) {
    turnstileToken = token;
    $('#submit').attr('disabled', false);
  }

  // Function to handle Turnstile expiration or error
  function onTurnstileExpiredOrError() {
    turnstileToken = '';
    $('#submit').attr('disabled', true);
  }

  window.onload = function() {
    // Render Turnstile inside the #turnstile-container element
    turnstile.render('#turnstile-container', {
      theme: 'light',
      sitekey: '0x4AAAAAAAjxO7DcYLmXgE9d',  // Replace with your actual site key
      callback: onTurnstileSuccess,          // Token generated
      'error-callback': onTurnstileExpiredOrError,  // Error handling
      'expired-callback': onTurnstileExpiredOrError // Token expired
    });
  };
});
